import axios from 'axios'
import Store from '@/store'

//export const SANCTUM_CSRF_EDNPOINT = 'http://localhost:8001/sanctum/csrf-cookie'
//export const API_ENDPOINT = 'http://localhost:8001/api/'

//export const SANCTUM_CSRF_EDNPOINT = 'https://wctelemetrija.ina.hr/ina-rdc/public/sanctum/csrf-cookie'
//export const API_ENDPOINT = 'https://wctelemetrija.ina.hr/ina-rdc/public/api/'

export const SANCTUM_CSRF_EDNPOINT = 'https://wctelemetrija.eolgroup.eu/application/public/sanctum/csrf-cookie'
export const API_ENDPOINT = 'https://wctelemetrija.eolgroup.eu/application/public/api/'

//window.console.log("VUE_APP_AUTH: " + process.env.VUE_APP_AUTH);

let httpHeaders = {
    get: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    },

    post: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    },

    put: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    },

    destroy: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    },

    download: {
        
    }
}

if(process.env.VUE_APP_AUTH === 'spa' || process.env.VUE_APP_AUTH === 'SPA') {
    axios.defaults.withCredentials = true;
}

if(process.env.VUE_APP_AUTH === 'token' || process.env.VUE_APP_AUTH === 'TOKEN') {
    httpHeaders.get["Authorization"] = `Bearer ${Store.state.user.access_token}`;
    httpHeaders.post["Authorization"] = `Bearer ${Store.state.user.access_token}`;
    httpHeaders.put["Authorization"] = `Bearer ${Store.state.user.access_token}`;
    httpHeaders.destroy["Authorization"] = `Bearer ${Store.state.user.access_token}`;
    httpHeaders.download["Authorization"] = `Bearer ${Store.state.user.access_token}`;
}

//window.console.log("HTTP Headers: ");
//window.console.log(httpHeaders)

export function get(url) {
    return axios({
        method: 'GET',
        url: url,
        headers: httpHeaders.get
    })
}

export function post(url, payload) {
    return axios({
    	method: 'POST',
    	url: url,
    	data: payload,
    	headers: httpHeaders.post
    })
}

export function put(url, payload) {
    return axios({
        method: 'POST',
        url: url,
        data: payload,
        headers: httpHeaders.put
    })
}

export function destroy(url) {
    return axios({
        method: 'POST',
        url: url,
        headers: httpHeaders.destroy
    })
}

export function download(url) {
    return axios({
        method: 'GET',
        url: url,
        responseType: 'blob', // important
        headers: httpHeaders.download
    })
}

export function interceptors(cb) {
    axios.interceptors.response.use((res) => {
        return res;
    }, (err) => {
        cb(err)
        return Promise.reject(err)
    })
}