import { post, put, get, destroy, download, API_ENDPOINT, SANCTUM_CSRF_EDNPOINT } from '@/helpers/api'

export default {
    ROUTE_NAME (context, routeName) {
        context.commit('SET_CURRENT_ROUTE', routeName)
    },

    
    LOGIN (context, credentials) {
        return new Promise( (resolve, reject) => {
            post(API_ENDPOINT + 'auth/user-login', credentials) 
                .then(response => {
                    context.commit('SET_USER', response.data)
                    context.commit('SET_AUTHENTICATED', true)
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    

    LOGIN_SPA(context, credentials) {
        let responseData = {
            error: null,
            data: null,
            success: false

        }

        return new Promise((resolve, reject) => {
            get(SANCTUM_CSRF_EDNPOINT)
            .then(response => {
                //window.console.log("### CSRF TOKEN success ###");
                post(API_ENDPOINT + 'auth/login', credentials) 
                .then(response => {
                    //window.console.log(response.data)
                    context.commit('SET_USER', response.data)
                    context.commit('SET_AUTHENTICATED', true)

                    responseData.success = true
                    responseData.data = response.data

                    resolve(response)

                })
                .catch(error => {
                    window.console.error("### LOGIN error ###")
                    window.console.log(error)

                    responseData.success = false
                    responseData.error = error

                    reject(error)
                })
            })
            .catch(error => {
                window.console.log("### LOGIN CSRF TOKEN error ###");
                window.console.error(error)

                responseData.success = false
                responseData.error = error

                reject(error)
                
            })
        })
    },
  
    LOGOUT (context) {
        return new Promise( (resolve, reject) => {
            get(API_ENDPOINT + 'auth/logout')
            .then(response => {
                context.commit('SET_USER', null)
                context.commit('SET_AUTHENTICATED', false)
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },
  
    PASSWORD_RESET_REQUEST (context, email) {
        return new Promise( (resolve, reject) => {
            post(API_ENDPOINT + 'password/create', email)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    PASSWORD_RESET (context, credentials) {
        return new Promise( (resolve, reject) => {
            post(API_ENDPOINT + 'password/reset', credentials)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    PASSWORD_RESET_TOKEN_FIND(context, token) {
        return new Promise( (resolve, reject) => {
            get(API_ENDPOINT + 'password/find/' + token)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },
  
    

    PARAMETERS_DATA (context) {
        return new Promise( (resolve, reject) => {
            get(API_ENDPOINT + 'v1/parameters')
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    GET_SETTINGS(context) {
        return new Promise( (resolve, reject) => {
            get(API_ENDPOINT + 'v1/settings')
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    SAVE_SETTINGS(context, data) {
        return new Promise((resolve, reject) => {
            post(API_ENDPOINT + 'v1/settings/save', data)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    PUBLISH (context, payload) {
        return new Promise( (resolve, reject) => {
            post(API_ENDPOINT + 'v1/mqtt-publish', payload)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    DOWNLOAD_FILE(context, uuid) {
        return new Promise( (resolve, reject) => {
            window.console.log(API_ENDPOINT + 'v1/exports/'+ uuid + '/download')
            download(API_ENDPOINT + 'v1/exports/'+ uuid + '/download')
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    DELETE_FILE(context, uuid) {
        return new Promise( (resolve, reject) => {
            get(API_ENDPOINT + 'v1/exports/'+ uuid + '/delete')
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    EXPORT(context, data) {
        return new Promise((resolve, reject) => {
            //window.console.log(API_ENDPOINT + 'v1/exports/' + data.group)
            post(API_ENDPOINT + 'v1/exports/' + data.group, data.queryParameters)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    CASHIERS(context) {
        return new Promise( (resolve, reject) => {
            get(API_ENDPOINT + 'v1/cashiers')
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    MAP_BUSINESS_PREMISES(context) {
        return new Promise((resolve, reject) => {
            get(API_ENDPOINT + 'v1/parameters/business-premises')
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    WIDGETS_DATA (context, parameters) {
        return new Promise( (resolve, reject) => {
            post(API_ENDPOINT + 'v1/parameters/widgets-data', parameters)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    CREATE_MAINTENANCE(context, data) {
        return new Promise( (resolve, reject) => {
            post(API_ENDPOINT + 'v1/maintenances/create', data)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    UPDATE_MAINTENANCE(context, data) {
        return new Promise( (resolve, reject) => {
            post(API_ENDPOINT + 'v1/maintenances/'+ data.uuid + "/update", data)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },
    

    DELETE_MAINTENANCE(context, uuid) {
        return new Promise( (resolve, reject) => {
            get(API_ENDPOINT + 'v1/maintenances/'+ uuid + '/delete')
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    }


}