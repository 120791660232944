// export function camel (str) {
//   const camel = (str || '').replace(/-([^-])/g, g => g[1].toUpperCase());

//   return capitalize(camel);
// }

// export function camelActual (str) {
//   return (str || '').replace(/-(\w)/g, (_, c) => (c ? c.toUpperCase() : ''));
// }

// export function kebab (str) {
//   return (str || '').replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
// }

// export function capitalize (str) {
//   str = str || '';

//   return `${str.substr(0, 1).toUpperCase()}${str.slice(1)}`;
// }

// export function findProduct (store, id) {
//   return store.state.store.products.find(p => p.id === id);
// }

// export function isOnSale (variants) {
//   return variants.some(variant => {
//     return parseFloat(variant.price) < parseFloat(variant.compareAtPrice);
//   });
// }

// export function randomNumber (min, max) {
//   return Math.floor(Math.random() * max) + min;
// }

// export function randomString (length = 5) {
//   let text = '';
//   const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

//   for (let i = 0; i < length; i++) {
//     text += possible.charAt(Math.floor(Math.random() * possible.length));
//   }

//   return text;
// }

var CryptoJS = require('crypto-js')

export function randomPinNumber() {
  var pin = '';

  var random_number_1 = Math.floor(Math.random()* 9) + 1
  var random_number_2 = Math.floor(Math.random()* 9) + 1
  var random_number_3 = Math.floor(Math.random()* 9) + 1
  var random_number_4 = Math.floor(Math.random()* 9) + 1

  pin = random_number_1.toString() + "" + random_number_2.toString() + "" + random_number_3.toString() + "" + random_number_4.toString()

  return pin


}

const randomElement = (arr = []) => {
    return arr[Math.floor(Math.random() * arr.length)];
  };
  
  const kebab =  (str) => {
    return (str || '').replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
  };
  
  const toggleFullScreen = () => {
    let doc = window.document;
    let docEl = doc.documentElement;
  
    let requestFullScreen = docEl.requestFullscreen || docEl.mozRequestFullScreen || docEl.webkitRequestFullScreen || docEl.msRequestFullscreen;
    let cancelFullScreen = doc.exitFullscreen || doc.mozCancelFullScreen || doc.webkitExitFullscreen || doc.msExitFullscreen;
  
    if (!doc.fullscreenElement && !doc.mozFullScreenElement && !doc.webkitFullscreenElement && !doc.msFullscreenElement) {
      requestFullScreen.call(docEl);
    }
    else {
      cancelFullScreen.call(doc);
    }
  };

  export function getCurrentDate() {
      let dt = new Date()
      let currentDate = dt.getFullYear() + '-' + pad(parseInt(dt.getUTCMonth()) + 1) + '-' + pad(dt.getDate())

      return currentDate
  }

  export function getCurrentTime() {
      let dt = new Date()
      return dt.getHours() + ":" + String(dt.getMinutes()).padStart(2, "0")
  }

  export function formatDate (date) {
    //window.console.log("### formatDate ###")
    //window.console.log(date)
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
  }

  function pad(n){return n<10 ? '0'+n : n}

  export function getUserSettingsFromStorage(key) {
    var userSettings = JSON.parse(window.localStorage.getItem(key)) || null
    if(userSettings !== null) {
      var bytes  = CryptoJS.AES.decrypt(userSettings, process.env.VUE_APP_CRYPTO_JS_KEY);
      var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      userSettings = decryptedData
    }
    
    window.console.log(userSettings)

    return userSettings
  }

  export function storeUserSettingsToLocalStorage(key, data) {
    var userSettings = JSON.parse(window.localStorage.getItem(key)) || null

    if(userSettings == null) {
      userSettings = window.localStorage.setItem(key, JSON.stringify(data))
    }
    
    for(var property in data) {
      userSettings[property] = data[property]
    }

    var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(userSettings), process.env.VUE_APP_CRYPTO_JS_KEY).toString();
    //window.localStorage.setItem(key, JSON.stringify(userSettings))
    window.localStorage.setItem(key, JSON.stringify(ciphertext))
    window.console.log(userSettings)
    window.console.log(ciphertext)

    return userSettings
  }

  export function getUserApplicationRole() {
    var user = JSON.parse(window.localStorage.getItem(process.env.VUE_APP_KEY)) || null
    var userRole = null;

    //window.console.log(user)
    
    if(user == null || user == "" || user == undefined) {
      return null
    }

    var bytes  = CryptoJS.AES.decrypt(user, process.env.VUE_APP_CRYPTO_JS_KEY);
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    //window.console.log(decryptedData)

    user = decryptedData
    userRole = user.role

    if(user.role == null || user.role == undefined) {
      return null
    }

    //window.console.log("Utilities@getUserApplicationRole(): " + userRole)
    //window.console.log(user.type)

    return userRole

  }

  export function getUserApplicationPermissions() {
    var user = JSON.parse(window.localStorage.getItem(process.env.VUE_APP_KEY)) || null
    var userPermissions = [null];
    
    if(user == null) {
      return []
    }

    var bytes  = CryptoJS.AES.decrypt(user, process.env.VUE_APP_CRYPTO_JS_KEY);
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    user = decryptedData

    userPermissions = user.permissions

    if(user.permissions == null || user.permissions == undefined) {
      return []
    }

    //window.console.log("Utilities@getUserApplicationPermissions(): ")
    //window.console.log(user.type)

    /*userPermissions.forEach(item => {
      window.console.log("Permission: " + item);
    })*/

    return userPermissions
  }

  export function checkPermissions(requestedPermissions, permissions) {

    //window.console.log("xxx")
    //window.console.log(requestedPermissions)
    if(requestedPermissions.length == 0) {
      return true;
    }

    var permissionFound = false
    requestedPermissions.forEach(rp => {
      permissions.forEach(userPermission => {
        if(rp === userPermission) {
          permissionFound = true;
        }
      })
    })

    return permissionFound;
  }

  export function create_UUID(){
    var dt = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = (dt + Math.random()*16)%16 | 0;
        dt = Math.floor(dt/16);
        return (c=='x' ? r :(r&0x3|0x8)).toString(16);
    });

    return uuid;
  }

  export function isMobileDevice(obj) {    
    if(obj.$vuetify.breakpoint.mdAndDown) {
      return true;
    }

    return false;
  }

  export function getMonth2Digits(monthValue) {
      // 👇️ Add 1, because getMonth is 0-11
      const month = monthValue + 1;
    
      if (month < 10) {
        return '0' + parseInt(month);
      }
    
      return month;
  }

  export function getDay2Digits(dayValue) {
    const day = dayValue;
  
    if (day < 10) {
      return '0' + parseInt(day);
    }
  
    return day;
  }

  export function defaultAppSettings() {
    return {
      dt_invoices: {
        items_per_page: 10,
        columns: ["id", "invoice_nr", "sales_location_nr", "cashier_nr", "payment_method_name", "amount", "zoi", "eor", "transaction_id", "date_time", "action"]
      },

      dt_telemetry: {
        items_per_page: 10,
        columns: ["business_premise_name", "cashier_nr","cashier_name", "errors_str", "coins", "chng", "oldchng", "pfill", "last_update", "action"]
      },

      dt_exports: {
          items_per_page: 10,
          columns: ["id", "name", "export_status_name", "file_type_for_humans", "file_size_readable", "execution_time_for_humans", "data_group", "user", "created_at_formatted", "action"]
      },

      dt_cashier_maintenances : {
        items_per_page: 10,
        //columns: ["id","business_premise_name", "cashier_nr", "name", "telemetry.all_entrances", "telemetry.entrances_since_last_maintenance", "telemetry.last_maintenance.maintenance_timestamp", "all_entrances", "entrances_since_last_maintenance","action"]
        columns: ["business_premise_name", "cashier_nr", "name", "telemetry.all_entrances", "telemetry.entrances_since_last_maintenance", "telemetry.last_maintenance.maintenance_timestamp", "all_entrances", "entrances_since_last_maintenance","action"]
      },

      dt_maintenances : {
        items_per_page: 10,
        columns: ["id","business_premise_name", "cashier_nr",  "cashier.name", "servicemen", "maintenance_timestamp", "entrances", "tag", "cashier_name", "action"]
      },

      invoices: {
          filter_results_open: true
      },

      telemetry: {
          filter_results_open: true,
          map_open: true
      },

      maintenances: {
          filter_results_open: true
      }
    }
  }

  export function getAppSettingsFromLocalStorage(obj) {
    let appSettings = JSON.parse(window.localStorage.getItem(process.env.VUE_APP_SETTINGS_KEY)) || null
    //window.console.log("=== === === === === === === === === === === === === === === === === === === === === ===")
    //window.console.log(appSettings)

    if(appSettings) {
      let setting = appSettings[obj.setting]
      //window.console.log("setting:")
      //window.console.log(setting)
      if(setting && setting !== undefined) {
        //window.console.log("value: ", setting[obj.key])
        return setting[obj.key]
      }
    }

    return null
  }

  export function getHoursDiff(startDate, endDate) {
    window.console.log("### getHoursDiff ###")
    window.console.log("startDate: ", startDate);
    window.console.log("endDate: ", endDate);

    //const startDateObj = new Date(startDate)
    //const endDateObj = new Date(endDate)

    startDate = startDate.replace(" ", "T");
    endDate = endDate.replace(" ", "T");

    window.console.log("startDate: ", startDate);
    window.console.log("endDate: ", endDate);

    const startDateObj = Date.parse(startDate) // ne dela na iphone (webkit jajca -- ne zna parsat yyyy-mm-dd)
    const endDateObj = Date.parse(endDate)

    

    window.console.log("startDateObj: " + startDateObj)
    window.console.log("endDateObj: " + endDateObj)

    const msInHour = 1000 * 60 * 60;

    let result = Math.round(Math.abs(endDateObj - startDateObj) / msInHour)

    //window.console.log("result: "+ Math.abs(endDateObj.getTime() - startDateObj.getTime()) / msInHour)
    window.console.log("result: "+ result)
  
    return Math.round(Math.abs(endDateObj - startDateObj) / msInHour);

    ///return Math.round(
      //Math.abs(endDateObj.getTime() - startDateObj.getTime()) / msInHour,
      //Math.abs(endDateObj - startDateObj) / msInHour
    //);
  }

  export function getMinutesDiff(startDate, endDate) {
    startDate = startDate.replace(" ", "T");
    endDate = endDate.replace(" ", "T");

    //const startDateObj = new Date(startDate)
    //const endDateObj = new Date(endDate)

    const startDateObj = Date.parse(startDate) // ne dela na iphone (webkit jajca -- ne zna parsat yyyy-mm-dd)
    const endDateObj = Date.parse(endDate)


    const msInMinutes = 1000 * 60;

    return Math.round(
      Math.abs(endDateObj- startDateObj) / msInMinutes,
    );
    
  
    /*return Math.round(
      Math.abs(endDateObj.getTime() - startDateObj.getTime()) / msInMinutes,
    );
    */
  }

  
  export default {
    randomElement,
    toggleFullScreen,
    kebab
  };